import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RiDoubleQuotesR } from "react-icons/ri";

export default function TestimonialCards({ cards }) {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      <div id="blogs" style={{ backgroundColor: "#684CF6" }}>
        <div className="relative w-full h-full flex flex-col items-center justify-start text-center ">
          <h1 className="text-white p-5 text-5xl font-semibold tracking-normal 
           mt-16 mb-10">
            What People Think About Us
          </h1>
          <div className="w-full m-auto">
            <div className="mt-10 mb-20 ">
              <Slider {...settings}>
                {cards.map((card) => (
                  <div
                    key={card.name}
                    className="max-w-sm bg-white rounded-2xl overflow-hidden"

                  >
                    <div className="px-10 py-14">
                      <p className="text-gray-700 text-xl font-poppins text-start">
                        {card.paragraph}
                      </p>
                      <div className="font-semibold text-lg mt-6 font-poppins text-start mb-2">
                        {card.name}
                      </div>
                      <div className="text-base mb-2 font-poppins text-start">
                        {card.companyName}
                      </div>
                      <div className="flex justify-end">
                        <RiDoubleQuotesR size={40} className="text-gray-700 " />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
