import React from "react";

export default function Cards({ imageSrc, description, description1 }) {
  return (
    <>
      <div className="max-w-sm bg-white border p-10 border-gray-200 rounded-3xl shadow flex flex-col items-center justify-center m-4">
        <img
          src={imageSrc}
          className="w-48 h-auto mb-4 rounded-3xl "
          style={{ maxWidth: "75%", maxHeight: "250px", objectFit: "cover" }}
          alt={imageSrc}
        />
        <p className="mb-3 font-poppins font-normal text-black ">{description}</p>
        <p className="mb-3 font-poppins font-normal text-black ">{description1}</p>
      </div>
    </>
  );
}
