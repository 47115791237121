import React from "react";
import Slider from "react-slick";

import { client1ImgUrl, client2ImgUrl, client3ImgUrl, client4ImgUrl, client5ImgUrl } from "../../constants/assets";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function LogoSlider() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <div className="">
        <div className="slider-container">
          <Slider {...settings}>
            <div>
              <img
                src={client1ImgUrl}
                alt="Logo 1"
                className="max-w-full h-auto w-4/5"
              />
            </div>
            <div>
              <img
                src={client2ImgUrl}
                alt="Logo 2"
                className="max-w-full h-auto w-4/5"
              />
            </div>
            <div>
              <img
                src={client3ImgUrl}
                alt="Logo 3"
                className="max-w-full h-auto w-4/5"
              />
            </div>
            <div>
              <img
                src={client4ImgUrl}
                alt="Logo 4"
                className="max-w-full h-auto w-4/5"
              />
            </div>
            <div>
              <img
                src={client5ImgUrl}
                alt="Logo 5"
                className="max-w-full h-auto w-4/5"
              />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}
