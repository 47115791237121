import React from "react";
import FeatureCard from "../Components/Sections/FeatureCard";
import { 
  Parallax1ImgUrl, 
  ftAdministrationImgUrl, 
  ftInvoiceImgUrl, 
  ftCustomerImgUrl, 
  ftETenderImgUrl, 
  ftDepositsImgUrl, 
  ftAccountsImgUrl, 
  ftNotificationImgUrl,
} from "../constants/assets";

export default function MoreFeatures() {
  const feCardList = [
    {
      imageSrc: ftAdministrationImgUrl,
      title: "Adminstration",
    },
    {
      imageSrc: ftAccountsImgUrl,
      title: "Accounts",
    },
    {
      imageSrc: ftCustomerImgUrl,
      title: "Customer",
    },
    {
      imageSrc: ftNotificationImgUrl,
      title: "Notification",
    },
    {
      imageSrc: ftInvoiceImgUrl,
      title: "GST Invoice",
    },
    {
      imageSrc: ftDepositsImgUrl,
      title: "FD & AD PG",
    },
    {
      imageSrc: ftETenderImgUrl,
      title: "E Tender Notification",
    },
  ];
  return (
    <>
      <FeatureCard cards={feCardList} image={Parallax1ImgUrl} />
    </>
  );
}
