export const backgroundImgUrl = 'images/Background.webp';
export const background2ImgUrl = 'images/Background2.webp';
export const client1ImgUrl = 'images/client-1.webp';
export const client2ImgUrl = 'images/client-2.webp';
export const client3ImgUrl = 'images/client-3.webp';
export const client4ImgUrl = 'images/client-4.webp';
export const client5ImgUrl = 'images/client-5.webp';
export const dashboardImgUrl = 'images/LapAndMobile.webp';
export const footer3dImgUrl = 'images/footer3d.webp';
export const footerBgImgUrl = 'images/footer-bg.webp';
export const ftAccountsImgUrl = 'images/ft-accounts.webp';
export const ftAdministrationImgUrl = 'images/ft-adminstration.webp';
export const ftCustomerImgUrl = 'images/ft-customer.webp';
export const ftDepositsImgUrl = 'images/ft-deposits.webp';
export const ftETenderImgUrl = 'images/ft-etender.webp';
export const ftExpenseImgUrl = 'images/ft-expense.webp';
export const ftInvoiceImgUrl = 'images/ft-invoice.webp';
export const ftNotificationImgUrl = 'images/ft-notification.webp';
export const ftProjectImgUrl = 'images/ft-project.webp';
export const ftReportImgUrl = 'images/ft-report.webp';
export const ftSupervisorImgUrl = 'images/ft-supervisor.webp';
export const ftToolImgUrl = 'images/ft-tool.webp';
export const ftWorkerImgUrl = 'images/ft-worker.webp';
export const img1Url = 'images/Img1.webp';
export const img2Url = 'images/Img2.webp';
export const img3Url = 'images/Img3.webp';
export const img4Url = 'images/Img4.webp';
export const logoImgUrl = 'images/logo.webp';
export const mobileViewImgUrl = 'images/Mobile.webp';
export const Parallax1ImgUrl = 'images/Parallax1.webp';
export const UntitledImgUrl = 'images/Untitled.webp';
export const BackGrdImgUrl = 'images/BackGrd.webp';
export const AppStoreIcon = 'images/app-store.png';
export const PlayStoreIcon = 'images/google-play.png';
