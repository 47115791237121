import React from 'react'
import LogoSlider from '../Components/Sections/LogoSlider'

export default function Slider() {
  return (
    <>
        <div className="slider-container" style={{ zIndex: 1 }}>
        <LogoSlider />
      </div>
    </>
  )
}
