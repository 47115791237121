import React from "react";
import { motion } from "framer-motion";

import { AppStoreIcon, PlayStoreIcon, dashboardImgUrl } from "../../constants/assets";

export default function BannerText() {
  const handleBookDemoClick = () => {
    window.location.href = "tel:+917907707474";
  }

  const downloadFromGooglePlay = () => {
    window.open('https://play.google.com/store/apps/details?id=com.ajfuture.cowallet', "_blank");
  }

  const downloadFromAppStore = () => {
    window.open('https://apps.apple.com/app/cowallet-for-contractors/id6469057193', "_blank");
  }
  return (
    <>
      <div className="relative z-10 container mx-auto px-4 h-full flex items-center mt-6">
        <div className="container mx-auto px-4 h-full flex flex-col md:flex-row justify-between items-center">
          <div className="md:flex-1 lg:ml-20 lg:-mt-20  ">
            <h1 className="text-4xl md:text-5xl lg:text-6xl  text-white font-poppins tracking-tighter  font-semibold mt-16  ">
              <span className="block md:block">Empower Your</span>
              <span className="block md:block">Contractor Journey</span>
            </h1>
            <h1 className="text-3xl md:text-3xl lg:text-4xl  text-white font-poppins tracking-tighter  font-normal mt-1  ">
              <span className="block md:block">With Our Cutting-Edge App</span>
            </h1>
            <div className="flex flex-col mt-6 space-y-5 md:flex-row md:space-x-5 md:space-y-0">
              <button className="bg-indigo-600 hover:bg-indigo-700 rounded-lg text-white px-4 md:px-10 font-poppins py-2 md:py-4"
                onClick={handleBookDemoClick}
              >
                Request Demo
              </button>
              <div className="flex justify-center gap-x-5">
                <button
                  onClick={downloadFromGooglePlay}
                >
                  <img
                    src={PlayStoreIcon}
                    alt="Play Store"
                    className="w-12 h-12 md:w-12 md:h-12"
                  />
                </button>
                <button
                  onClick={downloadFromAppStore}
                >
                  <img
                    src={AppStoreIcon}
                    alt="App Store"
                    className="w-12 h-12 md:w-12 md:h-12"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="md:flex-1">
            <motion.img
              className="mt-16"
              src={dashboardImgUrl}
              alt="Laptop"
              style={{ objectFit: "cover", width: "100%", height: "auto" }}
              animate={{
                y: [0, -20, 0],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
