import React from "react";

import { mobileViewImgUrl, ftProjectImgUrl, ftSupervisorImgUrl, ftWorkerImgUrl, ftExpenseImgUrl, ftToolImgUrl, ftReportImgUrl } from "../../constants/assets";

export default function Features() {
  const featuresData = [
    {
      title: "ManageProject",
      paragraph1: "Effortlessly oversee and navigate",
      paragraph2: "through all your projects in one",
      paragraph3: " centralized dashboard.",
      image: ftProjectImgUrl,
    },
    {
      title: "ManageWorkers",
      paragraph1: "Transform Financial Management",
      paragraph2: "with Contractor Wallet. Your solution",
      paragraph3: "to hassle-free worker finances!",
      image: ftWorkerImgUrl,
    },
    {
      title: "ManageTools",
      paragraph1: "Instantly locate tools and equipment,",
      paragraph2: "equipment, eliminating downtime",
      image: ftToolImgUrl,
    },
    {
      title: "SupervisorLogin",
      paragraph1: "Add expenses on the go, Stay in ",
      paragraph2: "control by monitoring on-site",
      paragraph3: " transactions in real-time.",
      image: ftSupervisorImgUrl,
    },
    {
      title: "AddExpense",
      paragraph1: "Stay on top of costs with instant  ",
      paragraph2: "updates on project-related",
      paragraph3: "expenditures.",
      image: ftExpenseImgUrl,
    },
    {
      title: "GetReports",
      paragraph1: "Simplify your workflow – all your ",
      paragraph2: "reports, just one click away!",
      image: ftReportImgUrl,
    },
  ];

  return (
    <>
      <div id="features" style={{ backgroundColor: "#f8faff" }}>
        <div className="relative w-full h-full flex flex-col items-center justify-start text-center">
          <h1 className="text-black mt-12 p-5 font-poppins text-5xl font-semibold tracking-normal ">
            Our Features
          </h1>
          <hr className="w-40  mt-2 mb-4  border-t-4 border-indigo-500" />
          <div className="flex flex-col sm:flex-row md:flex-row w-full items-center justify-center">
            <img
              src={mobileViewImgUrl}
              alt="Mobile"
              className="sm:h-1/2 sm:w-1/2 md:h-1/2 md:w-1/2 lg:h-1/4 lg:w-1/4 mt-14 sm:mt-0 object-cover order-1 sm:order-2"
            />
            <ul className="w-full sm:w-1/3 md:w-1/3 lg:mr-8 mt-4 sm:mt-24 order-2 sm:order-1">
              {featuresData.slice(0, 3).map((feature, index) => (
                <li
                  key={index}
                  className="mb-8 flex flex-col items-center justify-center"
                >
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className="h-1/4 w-1/4 mb-2 object-cover"
                  />
                  <h2 className="text-xl font-poppins font-semibold mb-2">
                    {feature.title}
                  </h2>
                  <p className="font-poppins">{feature.paragraph1}</p>
                  <p className="font-poppins">{feature.paragraph2}</p>
                  <p className="font-poppins">{feature.paragraph3}</p>
                </li>
              ))}
            </ul>
            <ul className="w-full sm:w-1/3 md:w-1/3 lg:ml-8 mt-4 sm:mt-24 order-3">
              {featuresData.slice(3).map((feature, index) => (
                <li
                  key={index}
                  className="mb-8 flex flex-col items-center justify-center"
                >
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className="h-1/4 w-1/4 mb-2 object-cover"
                  />
                  <h2 className="text-xl font-poppins font-semibold mb-2">
                    {feature.title}
                  </h2>
                  <p className="font-poppins">{feature.paragraph1}</p>
                  <p className="font-poppins">{feature.paragraph2}</p>
                  <p className="font-poppins">{feature.paragraph3}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
