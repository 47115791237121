import React from "react";
import { Parallax } from "react-parallax";
import Cards from "./Cards";

export default function BoxFeatures({ image, cards }) {
  return (
    <>
      <div className="sm:vh-screen md:vh-85" style={{ backgroundColor: "#f1fafb" }}>
        <Parallax
          className="min-h-screen relative mt-14 "
          bgImage={image}
          strength={500}
          bgImageStyle={{ minHeight: '80vh', backgroundSize: 'cover', }}
        >
          <div className="absolute top-5 left-0 right-0 bottom-0 flex flex-col items-center justify-start h-screen w-full text-center ">
            <h1 className="text-black p-5 text-5xl font-poppins font-semibold tracking-normal mt-20">
              Why CoWallet
            </h1>
            <hr className="w-40 mt-2 mb-4 border-t-4 border-indigo-500" />
            <div className={`flex flex-wrap justify-center mt-9 ${window.innerWidth < 768 ? 'max-h-96 overflow-y-auto hide-scrollbar mb-2 ' : ''}`}>
              {cards.map((card, index) => (
                <Cards
                  key={index}
                  imageSrc={card.imageSrc}
                  description={card.description}
                  description1={card.description1}
                />
              ))}
            </div>
          </div>
        </Parallax>
      </div>
    </>
  );
}
