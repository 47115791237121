import React, { useState } from "react";
import "./Working.css";

export default function Working({ image, options }) {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <div className="mb-10" >
        <div className="text-center ">
          <h1 className="text-black p-5 text-5xl font-poppins font-semibold tracking-normal  mt-10 ">
            How it Works?
          </h1>
          <p className="mt-2 text-2xl font-poppins">
            Revolutionizing Construction Management.
          </p>
          <div className="flex justify-center">
            <hr className="w-40 mt-2 mb-4 border-t-4 border-indigo-500" />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row">
          <div className={`w-full sm:w-1/2 order-${image ? 'first' : 'last'} md:w-1/2 order-${image ? 'first' : 'last'} `}>
            {image && (
              <img
                src={image}
                alt="Construction Image"
                className="w-full h-full object-cover"
              />
            )}
          </div>

          <div className="w-full lg:w-1/2 flex flex-col justify-start">
            <div className="flex flex-col mt-24">
              {options.map((option, index) => (
                <div
                  key={index}
                  className={`option ${selectedOption === option.heading
                      ? "selected"
                      : "unselected"
                    }`}
                  onClick={() => handleOptionClick(option.heading)}
                >
                  <h2 className="text-xl font-poppins font-semibold mt-0 m-5">
                    {option.heading}
                  </h2>
                  <p className="text-sm font-poppins sm:text-base md:text-base lg:text-lg xl:text-lg m-5">
                    {option.paragraph}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
