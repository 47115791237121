import React from "react";
import Working from "../Components/Sections/Working";
import { dashboardImgUrl } from "../constants/assets";

export default function HowItWorks() {
  const options = [
    {
      heading: "Create Your Tasks",
      paragraph:
        "Effortlessly launch your construction projects with Contractor Wallet. Create project timelines, assign tasks, and set milestones effortlessly. Streamline project initiation with just a few clicks. On-Site Empowerment:",
    },
    {
      heading: "Build Your Flow",
      paragraph:
        "Grant on-site supervisors direct control from the field. Manage projects, allocate tasks, and monitor real-time progress. Contractor Wallet provides the flexibility to make crucial decisions on the spot, enhancing on-site empowerment. Unified Collaboration:",
    },
    {
      heading: "Manage All Users",
      paragraph:
        "Foster collaboration among site engineers, supervisors, and workers seamlessly. Contractor Wallet enhances communication, facilitates real-time updates, and ensures everyone is on the same page. Streamline project teamwork effortlessly.",
    },
  ];

  return (
    <>
      <Working image={dashboardImgUrl} options={options} />
    </>
  );
}
