import React from "react";
import BoxFeatures from "../Components/Sections/BoxFeatures";
import { Parallax1ImgUrl } from "../constants/assets";
import { img1Url, img2Url, img3Url, img4Url } from "../constants/assets";

export default function Box() {
  const cardList = [
    {
      imageSrc: img1Url,
      title: "Card 1",
      description: "Manage all projects in ",
      description1: "one place",
    },
    {
      imageSrc: img2Url,
      title: "Card 2",
      description: "Add expenses and ",
      description1: "income",
    },
    {
      imageSrc: img3Url,
      title: "Card 3",
      description: "Know your workers ",
      description1: "and party Details",
    },
    {
      imageSrc: img4Url,
      title: "Card 4",
      description: "Get your reports in ",
      description1: "seconds",
    },
  ];
  return (
    <>
      <BoxFeatures image={Parallax1ImgUrl} cards={cardList} />
    </>
  );
}
