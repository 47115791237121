import React, { useState } from "react";

import { logoImgUrl } from "../../constants/assets";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const loginUrl = process.env.REACT_APP_LOGIN_URL

  const Image = () => <img className="w-[160px] mx-2" src={logoImgUrl} />;
  const handleClick = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setIsOpen(false); // Close the mobile menu if a link is clicked
    }
  };

  return (
    <nav className="bg-white   fixed top-0 w-full z-50 py-4  p-4">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <a className="text-white text-lg cursor-pointer font-bold" onClick={() => handleClick("home")}>
            <Image />
          </a>
        </div>
        <div className="hidden md:flex items-center space-x-4">
          <a className="text-black  hover:text-indigo-800 px-2 py-2 cursor-pointer font-poppins font-medium" onClick={() => handleClick("home")}>Home</a>
          <a className="text-black  hover:text-indigo-800 px-2 py-2 cursor-pointer font-poppins font-medium">About Us</a>
          <a className="text-black  hover:text-indigo-800 px-2 py-2 cursor-pointer font-poppins font-medium" onClick={() => handleClick("features")}>Feature</a>
          <a className="text-black  hover:text-indigo-800 px-2 py-2 cursor-pointer font-poppins font-medium" onClick={() => handleClick("faqSection")}>FAQ</a>
          <a className="text-black  hover:text-indigo-800 px-2 py-2 cursor-pointer font-poppins font-medium">Career</a>
          <a className="text-black  hover:text-indigo-800 px-2 py-2 cursor-pointer font-poppins font-medium" onClick={() => handleClick("blogs")}>Blogs</a>
          <a className="text-black  hover:text-indigo-800 px-2 py-2 cursor-pointer font-poppins font-medium" onClick={() => handleClick("contact")}>Contact</a>
        </div>
        <div className="md:hidden">
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="inline-flex items-center justify-center p-2  text-black hover:text-black  focus:outline-none  "
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            {isOpen ? (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>
        <div className="hidden md:flex items-center">
          <button className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
            <a href={loginUrl}>Sign In</a>
          </button>
        </div>
      </div>
      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-white">
          <a className="text-black  hover:text-orange-400 block px-4 py-2 font-poppins font-medium" onClick={() => handleClick("home")}>Home</a>
          <a className="text-black  hover:text-orange-400 block px-4 py-2 font-poppins font-medium">About</a>
          <a className="text-black  hover:text-orange-400 block px-4 py-2 font-poppins font-medium" onClick={() => handleClick("features")}>Features</a>
          <a className="text-black  hover:text-orange-400 block px-4 py-2 font-poppins font-medium" onClick={() => handleClick("faqSection")}>FAQ</a>
          <a className="text-black  hover:text-orange-400 block px-4 py-2 font-poppins font-medium">Career</a>
          <a className="text-black  hover:text-orange-400 block px-4 py-2 font-poppins font-medium" onClick={() => handleClick("blogs")}>Blogs</a>
          <a className="text-black  hover:text-orange-400 block px-4 py-2 font-poppins font-medium" onClick={() => handleClick("contact")}>Contact</a>
          <a className="text-black  hover:text-orange-400 block px-4 py-2 font-poppins font-medium" href={loginUrl}>Sign In</a>
        </div>
      )}
    </nav>
  );
};
export default NavBar;