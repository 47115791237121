import React from "react";
import { motion } from "framer-motion";
import { FaInstagram, FaFacebook } from "react-icons/fa";

import { footerBgImgUrl } from "../../constants/assets";

export default function Footer() {
  const footerStyle = {
    backgroundImage: `url(${footerBgImgUrl})`,
  };

  const listItemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5, type: "spring", stiffness: 120 },
    },
    hover: { scale: 1.05, transition: { duration: 0.3 } },
  };

  const underlineVariants = {
    hidden: { scaleX: 0 },
    visible: { scaleX: 1, transition: { duration: 0.5 } },
    hover: { scaleX: 1.05, transition: { duration: 0.3 } },
  };
  return (
    <>
      <footer id="contact" className="bg-gray-900 text-white py-24" style={footerStyle}>
        <div className="container mx-auto flex flex-wrap justify-center">
          <div className="w-full sm:w-1/2 lg:w-1/4 mb-4 sm:mb-0">
            <h3 className="text-2xl font-bold mb-10 text-indigo-700">
              Company
            </h3>
            <motion.ul className="list-none p-0">
              <motion.li
                variants={listItemVariants}
                initial="hidden"
                whileHover="hover"
                animate="visible"
                transition={{ delay: 0.1 }}
                className="py-2 group relative"
              >
                <motion.a
                  variants={underlineVariants}
                  initial="hidden"
                  whileHover="hover"
                  animate="visible"
                  transition={{ delay: 0.1 }}
                  href="#"
                  className="inline-block"
                >
                  Home
                </motion.a>
              </motion.li>
              <motion.li
                variants={listItemVariants}
                initial="hidden"
                whileHover="hover"
                animate="visible"
                transition={{ delay: 0.1 }}
                className="py-2 group relative"
              >
                <motion.a
                  variants={underlineVariants}
                  initial="hidden"
                  whileHover="hover"
                  animate="visible"
                  transition={{ delay: 0.1 }}
                  href="#"
                  className="inline-block"
                >
                  About Us
                </motion.a>
              </motion.li>
              <motion.li
                variants={listItemVariants}
                initial="hidden"
                whileHover="hover"
                animate="visible"
                transition={{ delay: 0.1 }}
                className="py-2 group relative"
              >
                <motion.a
                  variants={underlineVariants}
                  initial="hidden"
                  whileHover="hover"
                  animate="visible"
                  transition={{ delay: 0.1 }}
                  href="#"
                  className="inline-block"
                >
                  Careers
                </motion.a>
              </motion.li>
              <motion.li
                variants={listItemVariants}
                initial="hidden"
                whileHover="hover"
                animate="visible"
                transition={{ delay: 0.1 }}
                className="py-2 group relative"
              >
                <motion.a
                  variants={underlineVariants}
                  initial="hidden"
                  whileHover="hover"
                  animate="visible"
                  transition={{ delay: 0.1 }}
                  href="#"
                  className="inline-block"
                >
                  Contact Us
                </motion.a>
              </motion.li>
            </motion.ul>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4 mb-4 sm:mb-0">
            <h3 className="text-2xl font-bold mb-10 text-indigo-700">
              Contact Info
            </h3>
            <motion.address
              variants={listItemVariants}
              initial="hidden"
              whileHover="hover"
              animate="visible"
              transition={{ delay: 0.1 }}
              className="py-2 group relative"
            >
              <a href="tel:+917907707474">+91 79077 07474</a>
            </motion.address>
            <motion.address
              variants={listItemVariants}
              initial="hidden"
              whileHover="hover"
              animate="visible"
              transition={{ delay: 0.1 }}
              className="py-2 group relative"
            >
              <a href="mailto:info@ajfuture.in">info@ajfuture.in</a>
            </motion.address>
            <motion.address
              variants={listItemVariants}
              initial="hidden"
              whileHover="hover"
              animate="visible"
              transition={{ delay: 0.1 }}
              className="py-2 group relative"
            >
              <a href="http://www.ajfuture.in" >ajfuture.in</a>
            </motion.address>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4 mb-4 sm:mb-0">
            <h3 className="text-2xl font-bold mb-10 text-indigo-700">
              Office Address
            </h3>
            <address className="py-2">AJ Future Development LLP</address>
            <address className="py-2">Malappuram, Kerala</address>
            <div className="flex mt-2">
              <a
                href="https://www.instagram.com/contractorwallet/?igsh=cDc5ajh3bWxtcHJs"
                className="text-white hover:text-indigo-700 mr-4 py-2"
              >
                <FaInstagram size={24} />
              </a>
              <a href="https://www.facebook.com/people/Contractor-wallet/100092007300530/?mibextid=ZbWKwL" className="text-white hover:text-indigo-700 py-2">
                <FaFacebook size={24} />
              </a>
            </div>
          </div>
        </div>
        <div className="absolute  left-1/2 mt-8 transform -translate-x-1/2 mb-4">
          <p className="text-sm">&copy; 2024 AJ Future Development LLP</p>
        </div>
      </footer>
    </>
  );
}
