import React from 'react'

import Navbar from "../Components/Layout/NavBar";
import Home from "../Pages/Home";
import Box from "../Pages/Box";
import Slider from "../Pages/Slider";
import OurFeatures from "../Pages/OurFeatures";
import MoreFeatures from "../Pages/MoreFeatures";
import HowItWorks from "../Pages/HowItWorks";
import Footer from "../Components/Layout/Footer";
import Testimonial from "../Pages/Testimonial";
import FaQ from "../Pages/FaQ";

import "typeface-poppins";
import "../App.css";

const HomePage = () => {
    return (
        <>
            <Navbar />
            <Home />
            <Box />
            <Slider />
            <OurFeatures />
            <MoreFeatures />
            <HowItWorks />
            <Testimonial />
            <FaQ />
            <Footer />
        </>
    )
}

export default HomePage