import React from "react";
import { Parallax } from "react-parallax";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function FeatureCard({ image, cards }) {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <div
        className="sm:vh-screen md:vh-85"
        style={{ backgroundColor: "#f1fafb" }}
      >
        {/* <Parallax
          className="min-h-60vh relative "
          bgImage={image}
          strength={500}
          bgImageStyle={{ minHeight: "80vh", backgroundSize: "cover" }}
        > */}
        <Parallax
          className="min-h-screen relative mt-14 "
          bgImage={image}
          strength={500}
          bgImageStyle={{ minHeight: '80vh', backgroundSize: 'cover', }}
        >
          <div className="absolute top-5 left-0 right-0 bottom-0 flex flex-col items-center justify-start h-screen w-full text-center   ">
            <h1 className="text-black p-5 text-5xl font-poppins  font-semibold tracking-normal mt-20 ">
              More Features
            </h1>
            <hr className="w-40  mt-2 mb-4  border-t-4 border-indigo-500" />
            <div className="w-full m-auto">
              <div className="mt-20 mb-20 ">
                <Slider {...settings}>
                  {cards.map((card) => (
                    <div
                      key={card.title}
                      className="max-w-xs mx-auto border-none  rounded-2xl overflow-hidden shadow-lg"
                      style={{ margin: "0 10px" }}
                    >
                      <img
                        className="w-full   h-56 object-cover"
                        src={card.imageSrc}
                      />
                      <div className="px-7  py-7 bg-white">
                        <div className="font-medium font-poppins text-md mb-2">
                          {card.title}
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
    </>
  );
}
