import React from 'react'
import TestimonialCards from '../Components/Sections/TestimonialCards';

export default function Testimonial() {

  const data = [
    {
      name: "Sajeer",
      companyName: "Thonikkadavath Developers",
      paragraph: "“Cowallet transformed our construction projects with its seamless integration and efficiency. A game-changer in construction management",
    },
    {
      name: "Noushad",
      companyName: "Munna Construction",
      paragraph: "Managing sites is a breeze with Cowallet. Accessing plans and reports on the go has made my job efficient and organized."
      ,
    },
    {
      name: "Ajnas",
      companyName: "AJ Constructions",
      paragraph: "Cowallet simplified our estimation process, saving time and improving accuracy. A powerful tool for construction cost analysis."

    },
    {
      name: "Shameem",
      companyName: "Thoombalakkadan Constructions",
      paragraph: "Effortless collaboration and streamlined communication – Cowallet has significantly improved our project coordination.",
    },
  ];

  return (
    <>
      <TestimonialCards cards={data} />
    </>
  )
}
