import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';

const Questions = () => {


  return (
    <>
      <div id='faqSection' className="container mx-auto p-4 flex flex-col  ">
        <div className='lg:mx-32'  >
          <h2 className="text-3xl font-bold mb-2 mt-10 font-poppins items-start text-indigo-700">Frequently Asked Questions</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10 mb-8 lg:mx-32">

          <div className=''>
            <ul>
              <li className="mb-4 flex flex-col">
                <div className="flex items-center ">
                  <FaQuestionCircle className="mr-2 text-indigo-700" />
                  <h4 className="text-lg font-poppins font-bold">What is Contractor Wallet?</h4>
                </div>
                <p className="ml-2 border-l-2 pl-4 font-poppins border-indigo-300">Contractor Wallet is an innovative construction management software designed to streamline project operations, enhance collaboration, and simplify financial management for construction professionals.</p>
              </li>
              <li className="mb-4  flex flex-col">
                <div className="flex items-center">
                  <FaQuestionCircle className="mr-2  text-indigo-700" />
                  <h4 className="text-lg font-poppins font-bold">How can Contractor Wallet benefit my construction projects?</h4>
                </div>
                <p className="ml-2 border-l-2 pl-4 font-poppins border-indigo-300">Contractor Wallet empowers you to manage projects, supervise on-site operations, handle expenses, and gain valuable insights through robust reporting tools, ultimately improving efficiency and project success.</p>
              </li>
              <li className="mb-4  flex flex-col">
                <div className="flex items-center">
                  <FaQuestionCircle className="mr-2  text-indigo-700" />
                  <h4 className="text-lg font-poppins font-bold">Is Contractor Wallet easy to set up?</h4>
                </div>
                <p className="ml-2 border-l-2 font-poppins pl-4 border-indigo-300">Absolutely! Contractor Wallet is designed with user-friendliness in mind. The onboarding process is straightforward, and our support team is always ready to assist you.</p>
              </li>
              <li className="mb-4 flex flex-col">
                <div className="flex items-center">
                  <FaQuestionCircle className="mr-2  text-indigo-700" />
                  <h4 className="text-lg font-poppins font-bold">Are there any hidden fees?</h4>
                </div>
                <p className="ml-2 border-l-2 pl-4 font-poppins border-indigo-300">No hidden fees. Our pricing is transparent, and you only pay for the features and services you choose.</p>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li className="mb-4  flex flex-col">
                <div className="flex items-center">
                  <FaQuestionCircle className="mr-2  text-indigo-700" />
                  <h4 className="text-lg font-bold font-poppins">What training is provided for new users?</h4>
                </div>
                <p className="ml-2 border-l-2 pl-4 border-indigo-300 font-poppins">We offer comprehensive training materials, tutorials, and live support sessions to ensure you and your team are confident and proficient in using Contractor Wallet.</p>
              </li>
              <li className="mb-4  flex flex-col">
                <div className="flex items-center">
                  <FaQuestionCircle className="mr-2  text-indigo-700" />
                  <h4 className="text-lg font-bold font-poppins">How secure is my data with Contractor Wallet?</h4>
                </div>
                <p className="ml-2 border-l-2 pl-4 border-indigo-300 font-poppins">Security is our top priority. Contractor Wallet employs robust encryption measures and follows industry best practices to safeguard your data. We prioritize your privacy and adhere to stringent data protection standards.</p>
              </li>
              <li className="mb-4  flex flex-col">
                <div className="flex items-center">
                  <FaQuestionCircle className="mr-2  text-indigo-700" />
                  <h4 className="text-lg font-bold font-poppins">What is the pricing structure for Contractor Wallet?</h4>
                </div>
                <p className="ml-2 border-l-2 pl-4 border-indigo-300 font-poppins">Our pricing is based on a subscription model. Please visit our Pricing page for detailed information on plans and features.</p>
              </li>
              <li className="mb-4  flex flex-col">
                <div className="flex items-center">
                  <FaQuestionCircle className="mr-2  text-indigo-700" />
                  <h4 className="text-lg font-bold font-poppins">What support options are available?</h4>
                </div>
                <p className="ml-2 border-l-2 pl-4 border-indigo-300 font-poppins">We provide various support channels, including email support, live chat, and a comprehensive knowledge base. Our support team is dedicated to ensuring you have a smooth experience with Contractor Wallet.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Questions;