import React from 'react'

import Navbar from '../Components/Layout/NavBar'
import "../App.css";

const PrivacyPolicy = () => {
    return (
        <>
            <Navbar />
            <div className="p-20 mt-10" style={{ backgroundColor: "#f8faff" }}>
                <p className="text-2xl font-poppins mb-3">Privacy Policy for CO-Wallet</p>
                <p className="font-poppins">
                    This privacy policy outlines how we, the creators of CO-Wallet,
                    collect, use, and protect the information you provide through our
                    mobile app. By using CO-Wallet, you agree to the terms of this privacy
                    policy.
                </p>
                <p className="text-xl font-poppins mt-3 mb-3">Information Collection and Use</p>
                <ul className="list-disc ml-5">
                    <li className="font-poppins">
                        CO-Wallet collects information that you provide to us directly, such
                        as your name, email address, and project details.
                    </li>
                    <li className="font-poppins">
                        CO-Wallet may also collect information about your use of the app,
                        such as which features you access and how frequently.
                    </li>
                    <li className="font-poppins">
                        We use this information to provide and improve our app, to
                        communicate with you about your projects, and to respond to your
                        requests for support.
                    </li>
                </ul>
                <p className="text-xl font-poppins mt-3 mb-3">Sharing of Information</p>
                <ul className="list-disc ml-5">
                    <li className="font-poppins">
                        CO-Wallet does not share your information with third parties for
                        marketing purposes.
                    </li>
                    <li className="font-poppins">
                        We may share your information with third parties who provide
                        services to us, such as hosting our app. These third parties are
                        subject to strict confidentiality obligations.
                    </li>
                    <li className="font-poppins">
                        We may also disclose your information if required by law or in
                        response to a valid legal request.
                    </li>
                </ul>
                <p className="text-xl font-poppins mt-3 mb-3">Data Security</p>
                <ul className="list-disc ml-5">
                    <li className="font-poppins">
                        CO-Wallet takes steps to secure your information, including using
                        encryption and secure servers. However, no method of transmission
                        over the internet or method of electronic storage is completely
                        secure, so we cannot guarantee its absolute security.
                    </li>
                </ul>
                <p className="text-xl font-poppins mt-3 mb-3">Changes to This Privacy Policy</p>
                <ul className="list-disc ml-5">
                    <li className="font-poppins">
                        We may update this privacy policy from time to time, and we will
                        notify you of any changes through the app or by email. By continuing
                        to use CO-Wallet after changes are made, you agree to be bound by
                        the updated privacy policy.
                    </li>
                </ul>
                <p className="text-xl font-poppins mt-3 mb-3">Contact Us</p>
                <ul className="list-disc ml-5">
                    <li className="font-poppins">
                        If you have any questions or concerns about our privacy policy or
                        the information we collect, please contact us at
                        ajfuturedevelopment@gmail.com.
                    </li>
                </ul>
                <p className="font-poppins font-medium mt-10">Effective Date: 01/02/2023.</p>
            </div>
        </>
    )
}

export default PrivacyPolicy