import React from 'react'
import Questions from '../Components/Sections/Questions'
import FaqDemo from '../Components/Sections/FaqDemo'

export default function FaQ() {

  return (
    <>
      <Questions />
      <FaqDemo />
    </>
  )
}
