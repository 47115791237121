import React from "react";
import BannerText from "./BannerText";

export default function Banner({ image }) {
  return (

    <>
      <div id="home" className="relative min-h-screen mt-10 overflow-hidden h-2/4 ">
        {/* <div className="absolute top-0 left-0 w-full h-full " style={{ backgroundColor: "#765bef98" }}></div> */}
        <div
          className="absolute top-0 left-0 w-full bottom-0 h-full bg-cover mix-blend-overlay bg-center"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
        {/* Overlay */}
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <svg
          className="absolute  left-0 w-full h-96"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          fill="#ffffff"
          style={{ bottom: '-1px ' }}
        >
          <polygon points="0,100 100,100 100,50" />
        </svg>
        <BannerText />
      </div>
    </>

  );
}
