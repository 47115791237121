import React from 'react'

// import NavBar from '../Components/Layout/NavBar'
import Banner from '../Components/Sections/Banner'

import { background2ImgUrl } from '../constants/assets';


export default function Home() {
  return (
    <>
      <Banner image={background2ImgUrl}  />
    </>
  )
}
