import React from "react";

import { footer3dImgUrl } from '../../constants/assets';

export default function FaqDemo() {
  const handleBookDemoClick = () => {
    window.location.href = "tel:+917907707474";
  }
  return (
    <>
      <div class="grid grid-cols-1 bg-white md:grid-cols-2 gap-4 rounded-3xl shadow-xl mt-5 mb-20 lg:mx-40">
        <div class="md:col-span-1">
          <img src={footer3dImgUrl} />
        </div>

        <div className="md:col-span-1 text-center">
          <h3 className="text-3xl font-semibold font-poppins mt-14 text-indigo-500 mb-5">Get started with CoWallet</h3>
          <p className="text-gray-600 text-md font-poppins">Ready to Transform Your Construction Management? </p>
          <p className="text-gray-600 text-md font-poppins">Explore Contractor Wallet Today!</p>
          <button className="bg-indigo-500 hover:bg-slate-800 text-white font-sans px-4 md:px-8 py-2 md:py-2 mt-10 mb-5"
            onClick={handleBookDemoClick}
          >
            Book Demo
          </button>
        </div>
      </div>
    </>
  );
}
